import "./check.css";

import {
    Avatar,
    Badge,
    Table,
    Group,
    Text,
    ActionIcon,
    Anchor,
    ScrollArea,
    useMantineTheme, Loader, Select, Input,
} from '@mantine/core';
import {useEffect, useState} from "react";
import jwtInterceptor from "../../shared/jwtInterceptor";
import {PopupCheck} from "../PopupCheck";
import {PopupEdit} from "../PopupEdit";
import { Pagination } from '@mantine/core';
import {useNavigate, useParams} from "react-router-dom";
import {API, API_URL_ADMIN} from "../../config/axios.config";
import jwtInterceoptor from "../../shared/jwtInterceptor";
import {IconPencil} from "@tabler/icons-react"
window.Buffer = window.Buffer || require("buffer").Buffer

const jobColors = {
    "на модерации" : 'blue',
    "одобрено": 'cyan',
    "отклонено": 'pink',
};


export const Checks = () => {

    const [data, setData] = useState([]);
    const [popup, setPopup] = useState(false);
    const [path, setPath] = useState("");
    const [popupCheck, setPopupCheck] = useState(false);
    const [element, setElement] = useState();
    const [activePage, setPage] = useState(1);
    const [maxPage, setMaxPage] = useState(0);
    const [loading, setLoading] = useState(false);
    const [status, setStatus] = useState("all");
    const [value, setValue] = useState();
    const [typeSearch, setTypeSearch] = useState();
    const [error, setError] = useState();
    const user = useParams();
    const [reload, setReload] = useState(false);
    const navigator = useNavigate();

    useEffect(() => {
  /*      if (user.id) {
            jwtInterceptor.get(API_URL_ADMIN + "/check/get/by/user/" + user.id + "/" + activePage).then(r => {
                setMaxPage(r.data.data.maxPage);
                setData(r.data.data.data);
            });
        }*/
        if (user.id) {
            if (status !== "all") {
                setPage(1);
                jwtInterceptor.get(API_URL_ADMIN + "/check/all/by/type/id/" + user.id + "/" + status + "/" + activePage).then(r => {
                    setMaxPage(r.data.data.maxPage)
                    setData(r.data.data.data);
                });
            }
            else {
                setPage(1);
                jwtInterceptor.get(API_URL_ADMIN + "/check/all/by/id/" + user.id + "/" + activePage).then(r => {
                    setMaxPage(r.data.data.maxPage)
                    setData(r.data.data.data)
                });
            }
        }
        else {
            if (status !== "all") {
                setPage(1);
                jwtInterceptor.get(API_URL_ADMIN + "/check/all/type/" + status + "/" + activePage).then(r => {
                    setMaxPage(r.data.data.maxPage)
                    setData(r.data.data.data);
                });
            }
            else {
                setPage(1);
                jwtInterceptor.get(API_URL_ADMIN + "/check/all/" + activePage).then(r => {
                    setMaxPage(r.data.data.maxPage)
                    setData(r.data.data.data)
                });
            }
        }
    }, [reload, status])


    const theme = useMantineTheme();

    const rows = data.map((item) => { return (
        <tr key={item.id}>
            <td>
                <Group spacing="sm">
                    <Avatar onClick={() => {
                        setPopup(true);
                        setPath(item.imgPath.replace(".png", ""))
                    }} size={100} radius={30} src={API + "/image/" + item.imgPath.replace(".png", "")}/>
                    <Text size="sm" weight={500}>
                        {item?.user?.profile?.name}
                    </Text>
                </Group>
            </td>

            <td>
                <Text size="sm" color="dimmed">
                    {item.number}
                </Text>
            </td>

            <td>
                <Text size="sm" color="dimmed">
                    {item.gameCode ? String(item.gameCode).padStart(7, '0') : <></>}
                </Text>
            </td>
            <td>
                <Text size="sm" color="dimmed">
                    {item.amountProduct ? String(item.amountProduct) : <></>}
                </Text>
            </td>
            <td>
                <Text size="sm" color="dimmed">
                    {item.dateLoad}
                </Text>
            </td>
            <td>
                <Badge
                    color={jobColors[item.status.name.toLowerCase()]}
                    variant={theme.colorScheme === 'dark' ? 'light' : 'outline'}
                >
                    {item.status.name}
                </Badge>
            </td>
            <td>
                <Anchor size="sm" href="#" onClick={(event) => event.preventDefault()}>
                    {item?.user?.email}
                </Anchor>
            </td>
            <td>
                <Text size="sm" color="dimmed">
                    {item?.user?.phoneNumber}
                </Text>
            </td>
            <td>
                <Group spacing={0} position="right">
                    <ActionIcon onClick={() => {
                        setPopupCheck(true);
                        setPath(item.imgPath.replace(".png", ""));
                        setElement(item)
                    }}>
                        <IconPencil size={16} stroke={1.5}/>
                    </ActionIcon>
                </Group>
            </td>
        </tr>
    )
    })

    const handlerInput = (e) => {
        setValue(e.target.value);
        if (typeSearch) {
            setPage(1)
            if (e.target.value !== "") {
                setError();
                jwtInterceoptor.get(API_URL_ADMIN + `/check/search/${typeSearch}/${e.target.value}/${activePage}`).then(r => {
                    if (r.data.data.data !== null) {
                        setMaxPage(r.data.data.maxPage);
                        setData(r.data.data.data);
                    }
                    setLoading(false);
                })
            }
            else {
                jwtInterceoptor.get(API_URL_ADMIN + "/check/all/" + activePage).then(r => {
                    setMaxPage(r.data.data.maxPage);
                    setData(r.data.data.data);
                })
            }
        }
        else {
            setError("Не выбрано поле для поиска")
        }
    }


    const sortData = (type) => {
        setStatus(type)
    }

    const changePage = (e) => {
        setLoading(true);
        setPage(e);
        if (user.id) {
            if (status !== "all") {
                setPage(1);
                jwtInterceptor.get(API_URL_ADMIN + "/check/all/by/type/id/" + user.id + "/" + status + "/" + e).then(r => {
                    setMaxPage(r.data.data.maxPage)
                    setData(r.data.data.data);
                });
            }
            else {
                setPage(1);
                jwtInterceptor.get(API_URL_ADMIN + "/check/all/by/id/" + user.id + "/" + e).then(r => {
                    setMaxPage(r.data.data.maxPage)
                    setData(r.data.data.data)
                });
            }
        }
        else {
            if (status === "all") {
                jwtInterceptor.get(API_URL_ADMIN + "/check/all/" + e).then(r => {
                    setData(r.data.data.data);
                    setLoading(false);
                });
            }
            else {
                jwtInterceptor.get(API_URL_ADMIN + "/check/all/type/" + status + "/" + e).then(r => {
                    setMaxPage(r.data.data.maxPage)
                    setData(r.data.data.data);
                    setLoading(false);
                });
            }
        }
    }

    return (
        <>
            {popup ? <PopupCheck  popup={setPopup} path={path}/> : <></>}
            {popupCheck ? <PopupEdit reloadValue={reload} reload={setReload} popup={setPopupCheck} path={path} item={element}/> : <></>}
            <div className="check-body">
                <div style={{display: "flex", gap: "30px", padding: "10px"}}>
                    <div className="button-check" onClick={() => sortData('3')}>На модерации</div>
                    <div className="button-check" onClick={() => sortData("1")}>Одобренные</div>
                    <div className="button-check" onClick={() => sortData("2")}>Отклоненные</div>
                    <div className="button-check" onClick={() => sortData('all')}>Все</div>
                    {user ? <div className="button-check" onClick={() => navigator("/admin/dashboard/users")}>Назад</div> : <></>}
                </div>
                <div style={{width: "100%", display: "flex", justifyContent: "center", gap: "30px"}}>
                    <div>
                        <Select
                            onChange={(e) => setTypeSearch(e)}
                            label="Поле для поиска"
                            placeholder="Выбор..."
                            data={[
                                { value: 'phone', label: 'Телефон' },
                                { value: 'email', label: 'Почта' },
                                { value: 'code', label: 'Номер чека' }
                            ]}
                        />

                        <Input
                            value={value}
                            onChange={(e) => handlerInput(e)}
                            error={error}
                            errorText={error}
                        />
                    </div>

                </div>
                <ScrollArea>
                    <Table  verticalSpacing="sm">
                        <thead>
                        <tr>
                            <th>Участник</th>
                            <th>Номер</th>
                            <th>Игровой код</th>
                            <th>Продуктов в чеке</th>
                            <th>Дата загрузки</th>
                            <th>Статус</th>
                            <th>Почта</th>
                            <th>Телефон</th>
                            <th />
                        </tr>
                        </thead>
                        <tbody>{rows}</tbody>
                    </Table>
                </ScrollArea>

                <div style={{display: 'flex', justifyContent: 'center'}}>
                    {loading ?
                        <Loader color="yellow" variant="dots" />
                        :
                        <Pagination page={activePage} onChange={changePage} total={maxPage} color="yellow" size="lg" />
                    }
                </div>
            </div>
        </>
    );
}